import { httpPost } from '@/framework/http/HttpServices';

export class Query<TQuery, TResult> {
    name: string;
    query: TQuery;

    constructor(queryName: string, query: TQuery) {
        this.name = queryName;
        this.query = query;
    }
}

export class Command<TCommand> {
    name: string;
    command: TCommand;

    constructor(commandName: string, command: TCommand) {
        this.name = commandName;
        this.command = command;
    }
}

export function executeQuery<TQuery, TResult>({ name, query }: Query<TQuery, TResult>): Promise<TResult> {
    // @ts-ignore
    return httpPost(`queries/${name}`, query) as Promise<TResult>;
}

export async function executeCommand<TCommand>({ name, command }: Command<TCommand>): Promise<void> {
    // @ts-ignore
    await httpPost(`commands/${name}`, command);
}
