import type { BasicUser } from '@/framework/auth/BasicAuthUser';
import type { FrameworkPermissions } from '@/framework/auth/FrameworkPermissions';
import { defineStore } from 'pinia';
import {addTokenCommand, languagesSignedUrlPolicyQuery} from "@/framework/auth/AuthService";
import {getCurrentUser} from "@/framework/auth/AuthService";
import { signedUrlPolicyQuery } from '@/framework/auth/AuthService';

function defaultState() {
    return {
        token: '', // OAuth ID Token from Cognito. Use this for interactions with our server
        expiresOn: 0,
        accessToken: '', // OAuth Access token from Cognito. Use this for interactions with Cognito (e.g. when a user needs to change their password).
        lastRoute: '',
        user: defaultUser(),
        imagePolicyQueryString: '', // Query string to include on requests for images to Cloudfront in format ?Key...,
        languagesPolicyQueryString: '', // Query string to include on requests for language files to Cloudfront in format ?Key...,
        checkLoginPrompts: false,
        showOpenInApp: false
    };
}

function defaultUser(): BasicUser {
    return {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        permissions: [],
        emailVerified: false,
        loginCellNumberPrompt: true,
        hasOrganization: false,
        defaultLanguageId: ''
    };
}
const useStore = defineStore('authStore', {
    state: () => {
        // With cognito, I'm not sure how necessary this is... Storing state in Local Storage is primarily to get auth across tabs, and in Cognito AWS remembers our cookie and the redirect is quick.
        const storedState = localStorage.getItem('pinia-a-state');
        if (storedState) {
            const newState = JSON.parse(storedState);
            if (!newState.imagePolicyQueryString) {
                newState.imagePolicyQueryString = '';
            }
            if (!newState.languagesPolicyQueryString) {
                newState.languagesPolicyQueryString = '';
            }
            if (!newState.checkLoginPrompts) {
                newState.checkLoginPrompts = false;
            }
            if (!newState.showOpenInApp) {
                newState.showOpenInApp = false;
            }
            return newState;
        }
        return defaultState();
    },
    actions: {
        async finishLogin(payload: { token: string, expiresIn: number, accessToken: string }) {
            this.token = payload.token;
            this.accessToken = payload.accessToken;
            this.checkLoginPrompts = true;
            this.showOpenInApp = true;

            const now = new Date();
            now.setSeconds(now.getSeconds() + payload.expiresIn);
            this.expiresOn = now.getTime();

            const token = localStorage.getItem('notification-token');
            if (token) {
                await addTokenCommand(token);
            }
        },
        logoutUser() {
            this.token = '';
            this.expiresOn = 0;
            this.accessToken = '';
            this.lastRoute = '';
            this.user = defaultUser();
            this.languagesPolicyQueryString = '';
        },
        async loadPolicyQueryString() {
            const policyModel = await signedUrlPolicyQuery();
            this.imagePolicyQueryString = policyModel.policy;
        },
        async loadLanguagesPolicyQueryString() {
            const policyModel = await languagesSignedUrlPolicyQuery();
            this.languagesPolicyQueryString = policyModel.policy;
        },
        async reloadUser() {
            const user = await getCurrentUser();
            if (user) {
                this.user = user;
            }
        }
    },
    getters: {
        isLoggedIn: (state) => {
            // need this to make pinia fetch the latest value
            const expiresOn = state.expiresOn;
            return Boolean(state.token && expiresOn > new Date().getTime());
        },
        hasPermission: (state) => (permission: FrameworkPermissions) => {
            return state.user.permissions.includes(permission);
        }
    }
});

export default useStore;
