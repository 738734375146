import { defineStore } from 'pinia';
import { getCountries } from "@/components/countryRegionSelect/CountryService"
import {Country} from '@/components/countryRegionSelect/CountryRegionModel';
import useAppLanguageStore from '@/settings/languages/AppLanguageStore';

const usePurchaseStore = defineStore('purchaseStore', {
    state: () => {
        const purchases = localStorage.getItem('purchases');
        return {
            purchases: purchases ? new Map(JSON.parse(purchases)) : new Map<string, string>()
        };
    },
    actions: {
        addProductToOrder(productId: string, orderId: string) {
            this.purchases.set(productId, orderId);
            localStorage.setItem('purchases', JSON.stringify(Array.from(this.purchases.entries())));
        },
        removeProductFromOrder(productId: string) {
            this.purchases.delete(productId);
            localStorage.setItem('purchases', JSON.stringify(Array.from(this.purchases.entries())));
        },
        clearPurchases() {
            this.purchases = new Map<string, string>();
            localStorage.removeItem('purchases');
        }
    }
});

export default usePurchaseStore;