import type { RouteLocationNormalized, Router, RouteRecordNormalized, RouteRecordRaw } from 'vue-router';
import useAuthStore from "@/framework/auth/AuthStore";

export const authRoutes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/framework/auth/Login.vue'),
        meta: { public: true }
    },
    {
        path: '/logged-in',
        name: 'logged-in',
        component: () => import('@/framework/auth/LoggedIn.vue'),
        meta: { public: true }
    }
];
export function registerRouterAuthChecks(router: Router, routeNamesToForget: string[] = [ 'login', 'logged-in' ]) {
    router.beforeEach(function (to, from, next) {
        const authStore = useAuthStore();
        if (to.matched.some((record) => record.meta.public)) {
            next();
        } else if(authStore.isLoggedIn) {
            next();
        } else {
            next({ name: 'login' });
        }
    });
}