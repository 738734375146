import {executeQuery, Query} from "@/framework/http/CqrsService";
import {NotificationModel, WebNotificationModel} from "@/notifications/NotificationModels";

export interface GetUserNotificationQuery {
    UserId: string
}

export function getUserNotificationQuery(query: GetUserNotificationQuery): Promise<NotificationModel[]> {
    return executeQuery(new Query('GetUserNotificationQuery', query));
}

export function getWebNotificationsQuery(): Promise<WebNotificationModel[]> {
    return executeQuery(new Query('GetWebNotificationsQuery', {}));
}
