import {Command, executeCommand, executeQuery, Query} from '@/framework/http/CqrsService';
import {
    CompletedCourseModuleModel,
    CourseDetailModel,
    CourseSummary,
    InProgressCourseModel
} from '@/courses/CourseModel';
import {PaginationModel} from '@/pagination/PaginationModel';
import useAuthStore from "@/framework/auth/AuthStore";
import useParticipantCourseModuleStore from "@/courses/ParticipantCourseModuleStore";

interface CourseQuery {
    searchText: string;
    pageSize: number;
    pageNumber: number;
}

interface GetAllCoursesForGeneralPublicQuery {
    searchText: string;
    pageSize: number;
    pageNumber: number;
}

interface GetParticipantInProgressModulesQuery {
    searchText: string;
    pageSize: number;
    pageNumber: number;
}

export interface GetParticipantCourseModulesQuery {
}

export interface GetParticipantCourseModuleQuery {
    courseId: string;
}

export interface SetCurrentModuleCommand {
    courseId: string;
    moduleId: string;
}

export interface ProductSummary {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    sku: string;
}

export interface StripePaymentModel {
    paymentUrl: string;
}

export interface MobileCreateOrderCommand {
    sku: string;
    price: number;
    orderId: string;
}

export interface ResetModuleCommand {
    courseId: string;
    moduleId: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GetCompletedParticipantCoursesQuery {}
interface GetStripeSessionUrlQuery {
    orderId: string;
    returnHost: string;
    languageId: string;
}

interface ResendValidationEmailCommand{
    fromAdminApp?: boolean;
}

export interface GetAllGeneralAvailableProductsQuery { }

export interface CanReLaunchModuleQuery {
    courseId: string;
    moduleId: string;
}

export function getAllCourses(searchText: string, pageNumber: number, pageSize = 9): Promise<PaginationModel<CourseSummary>> {
    return executeQuery(new Query<CourseQuery, PaginationModel<CourseSummary>>('CourseQuery', {
        searchText,
        pageSize,
        pageNumber
    }));
}

export function getAllGeneralPublicCourses(searchText: string, pageNumber: number, pageSize = 9): Promise<PaginationModel<CourseSummary>> {
    return executeQuery(new Query<GetAllCoursesForGeneralPublicQuery, PaginationModel<CourseSummary>>('GetAllCoursesForGeneralPublicQuery', {
        searchText,
        pageSize,
        pageNumber
    }));
}

export function getStripeSessionUrl(orderId: string, hostname: string): Promise<StripePaymentModel> {
    const authStore = useAuthStore();
    return executeQuery(new Query<GetStripeSessionUrlQuery, StripePaymentModel>('GetStripeSessionUrlQuery', {
        orderId: orderId,
        returnHost: hostname,
        languageId: authStore.user.defaultLanguageId
    }));
}

export function purchaseCourses(orderId: string, courseIds: string[]): Promise<void> {
    return executeCommand(new Command('PurchaseCoursesCommand', {
        orderId: orderId,
        productIds: courseIds
    }));
}

export function getInProgressCourseModel(searchText: string, pageNumber: number, pageSize = 1000): Promise<PaginationModel<InProgressCourseModel>> {
    return executeQuery(new Query<GetParticipantInProgressModulesQuery, PaginationModel<InProgressCourseModel>>('GetParticipantInProgressModulesQuery', {
        searchText,
        pageSize,
        pageNumber
    }));
}

export function getCompletedParticipantCoursesQuery(query: GetCompletedParticipantCoursesQuery): Promise<CompletedCourseModuleModel[]> {
    return executeQuery(new Query<GetCompletedParticipantCoursesQuery, CompletedCourseModuleModel[]>('GetCompletedParticipantCoursesQuery', {}));
}

export function getParticipantCourseModulesQuery(): Promise<CourseDetailModel[]> {
    return executeQuery(new Query<GetParticipantCourseModulesQuery, CourseDetailModel[]>('GetParticipantCourseModulesQuery', {}));
}

export function getParticipantCourseModuleQuery(query: GetParticipantCourseModuleQuery): Promise<CourseDetailModel> {
    return executeQuery(new Query<GetParticipantCourseModuleQuery, CourseDetailModel>('GetParticipantCourseModuleQuery', query));
}

export async function setCurrentModuleCommand(command: SetCurrentModuleCommand): Promise<void> {
    await executeCommand(new Command<SetCurrentModuleCommand>('SetCurrentModuleCommand', command));
    const courseModuleStore = useParticipantCourseModuleStore();
    const module = courseModuleStore.getCourseModuleModel(command.moduleId);
    if (module) {
        await courseModuleStore.setModuleActive(command.moduleId);
        return;
    }
    await courseModuleStore.reloadParticipantCourseModules();
}

export async function resetModuleCommand(command: ResetModuleCommand): Promise<void> {
    await executeCommand(new Command<ResetModuleCommand>('ResetModuleCommand', command));
}
export function getAllGeneralAvailableProductsQuery(): Promise<ProductSummary[]> {
    const authStore = useAuthStore();
    return executeQuery(new Query<GetAllGeneralAvailableProductsQuery, ProductSummary[]>('GetAllGeneralAvailableProductsQuery', 
        { languageId: authStore.user.defaultLanguageId})
    );
}

export async function mobileCreateOrderCommand(command: MobileCreateOrderCommand): Promise<void> {
    await executeCommand(new Command<MobileCreateOrderCommand>('MobileCreateOrderCommand', command));
}

export async function resendValidationEmailCommand(): Promise<void> {
    await executeCommand(new Command<ResendValidationEmailCommand>('ResendValidationEmailCommand', {fromAdminApp: true}));
}

export function canReLaunchModuleQuery(query: CanReLaunchModuleQuery): Promise<boolean> {
    return executeQuery(new Query<CanReLaunchModuleQuery, boolean>('CanReLaunchModuleQuery', query));
}

