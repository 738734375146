import { defineStore } from 'pinia';
import {getCountries, getTimezonesQuery} from "@/components/countryRegionSelect/CountryService"
import {Country} from '@/components/countryRegionSelect/CountryRegionModel';
import useAppLanguageStore from '@/settings/languages/AppLanguageStore';
import {TimezoneOffsetModel} from '@/components/countryRegionSelect/TimezoneOffsetModel';

const useCountryStore = defineStore('countryStore', {
    state: () => {
        const storedTimezones = localStorage.getItem('timezoneOffsets');
        return {
            countries: [] as Country[],
            timezones: storedTimezones ? JSON.parse(storedTimezones) : [] as TimezoneOffsetModel[]
        };
    },
    actions: {
        async loadCountries() {
            const regionNamesInPreferredLanguage = new Intl.DisplayNames([useAppLanguageStore().language.language.locale], { type: 'region' });
            this.countries = await getCountries({ languageId: useAppLanguageStore().language.language.id });
            this.countries.forEach(c => {
                c.name = regionNamesInPreferredLanguage.of(c.alpha2Code) ?? '';
            });

            const usCountry = this.countries.find(c => c.alpha2Code === 'US');
            const usIndex = this.countries.findIndex( (country) => country.alpha2Code === 'US');
            this.countries.splice(usIndex, 1);
            this.countries.sort((countryA,countryB) => {
                if (countryA.name > countryB.name) {
                    return 1;
                } else if (countryA.name < countryB.name) {
                    return -1;
                }
                return 0;
            });
            if (usCountry) {
                this.countries.unshift(usCountry);
            }
        },
        async loadTimezones(force = false) {
            if (force || !this.timezones.length) {
                const decimalTimezones = await getTimezonesQuery();
                const timezoneModel = decimalTimezones.map(tz => {
                    const isNegative = tz < 0;
                    const offsetHour = Math.floor(Math.abs(tz));
                    const offsetMinutes = getMinutes(Math.abs(tz));
                    const isZero = offsetHour === 0 && offsetMinutes === 0;
                    const date = new Date(Date.UTC(2020, 1, 1, offsetHour, offsetMinutes, 0, 0));
                    return {
                        value: tz,
                        displayValue: (isZero ? '' : (isNegative ? '-' : '+')) + new Intl.DateTimeFormat(useAppLanguageStore().language.language.locale, {
                            timeStyle: 'short',
                            hourCycle: "h23", // h23 lets us display 00:00 instead of 24:00
                            timeZone: "UTC"
                        }).format(date)
                    };
                });
                this.timezones = timezoneModel;
                localStorage.setItem('timezoneOffsets', JSON.stringify(timezoneModel));
            }
        },
    },
    getters: {
        Countries: (state) => {
            return state.countries;
        },
        Timezones: (state) => {
            return state.timezones;
        },
    }
});

export default useCountryStore;

function getMinutes(offset: number): number {
    const remainder = offset % 1;
    switch (remainder) {
        case .25:
            return 15;
        case .5:
            return 30;
        case .75:
            return 45;
        default:
            return 0;
    }
}