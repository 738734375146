import { Command, executeCommand, executeQuery, Query } from '@/framework/http/CqrsService';
import type {OptionalInformation, RequiredInformation, UserDetails} from "@/profile/UserModel";
import {LinkedinProfile, Name} from "@/profile/UserModel";
import {WorkExperience} from '@/workExperience/WorkModel';
import {Education} from '@/education/EducationModel';
import {License} from '@/licenses/LicenseModel';

export interface GetUserDetailsQuery {
    id: string;
}
export interface GetWorkExperienceQuery {
    userId: string;
}
export interface GetEducationQuery {
    userId: string;
}
export interface GetLicenseQuery {
    userId: string;
}

export interface UpdateOwnOptionalInformationCommand extends OptionalInformation {}
export interface UpdateOwnRequiredInformationCommand extends RequiredInformation {}
export interface AddWorkExperienceCommand extends WorkExperience {}
export interface UpdateWorkExperienceCommand extends WorkExperience {}
export interface AddEducationCommand extends Education {}
export interface UpdateEducationCommand extends Education {}
export interface AddLicenseCommand extends License {}
export interface UpdateLicenseCommand extends License {}
export interface UpdateLoginInfoCommand {
    originalUserName: string;
    originalEmail: string;
    userName: string;
    email: string;
    avatarSrc: string;
    linkedInProfileUrl: string;
    fromAdminApp?: boolean;
}
export interface DeleteWorkExperienceCommand {
    id: string;
    userId: string;
}
export interface DeleteEducationCommand {
    id: string;
    userId: string;
}
export interface DeleteLicenseCommand {
    id: string;
    userId: string;
}
export interface DeleteAccountCommand {}

export interface GetS3AvatarsQuery {}

export interface SendSmsAppLinkCommand {
    appType: AppStoreType;
    phoneNumber: string;
}

export enum AppStoreType {
    AndroidPlayStore = 1,
    AppleAppStore
}

export interface UpdateCellNumberLoginCommand {
    cellNumber?: string;
    doNotShowCellPromptOnLogin: boolean;
}

export function getUserDetailsQuery(query: GetUserDetailsQuery): Promise<UserDetails | null> {
    return executeQuery(new Query<GetUserDetailsQuery, UserDetails | null>('GetUserDetailsQuery', query));
}

export async function updateOptionalInformationCommand(command: UpdateOwnOptionalInformationCommand): Promise<void> {
    await executeCommand(new Command<UpdateOwnOptionalInformationCommand>('UpdateOwnOptionalInformationCommand', command));
}

export async function updateLoginInfo(command: UpdateLoginInfoCommand): Promise<void> {
    command.fromAdminApp = false;
    await executeCommand(new Command<UpdateLoginInfoCommand>('UpdateLoginInfoCommand', command));
}

export async function updateRequiredInfo(command: UpdateOwnRequiredInformationCommand): Promise<void> {
    command.isPreferredSameAsLegalName = command.isPreferredSameAsLegalName ?? false;
    await executeCommand(new Command<UpdateOwnRequiredInformationCommand>('UpdateOwnRequiredInformationCommand', command));
}

export function getWorkExperience(query: GetWorkExperienceQuery): Promise<WorkExperience[]> {
    return executeQuery(new Query<GetWorkExperienceQuery, WorkExperience[]>('WorkExperienceQuery', query));
}

export async function addWorkExperience(command: AddWorkExperienceCommand): Promise<void> {
    await executeCommand(new Command<AddWorkExperienceCommand>('AddWorkExperienceCommand', command));
}

export async function updateWorkExperience(command: UpdateWorkExperienceCommand): Promise<void> {
    await executeCommand(new Command<UpdateWorkExperienceCommand>('UpdateWorkExperienceCommand', command));
}

export async function deleteWorkExperience(command: DeleteWorkExperienceCommand): Promise<void> {
    await executeCommand(new Command<DeleteWorkExperienceCommand>('DeleteWorkExperienceCommand', command));
}

export function getEducation(query: GetEducationQuery): Promise<Education[]> {
    return executeQuery(new Query<GetEducationQuery, Education[]>('EducationQuery', query));
}

export async function addEducation(command: AddEducationCommand): Promise<void> {
    await executeCommand(new Command<AddEducationCommand>('AddEducationCommand', command));
}

export async function updateEducation(command: UpdateEducationCommand): Promise<void> {
    await executeCommand(new Command<UpdateEducationCommand>('UpdateEducationCommand', command));
}

export async function deleteEducation(command: DeleteEducationCommand): Promise<void> {
    await executeCommand(new Command<DeleteEducationCommand>('DeleteEducationCommand', command));
}

export function getLicenses(query: GetLicenseQuery): Promise<License[]> {
    return executeQuery(new Query<GetLicenseQuery, License[]>('LicenseQuery', query));
}

export async function addLicense(command: AddLicenseCommand): Promise<void> {
    await executeCommand(new Command<AddLicenseCommand>('AddLicenseCommand', command));
}

export async function updateLicense(command: UpdateLicenseCommand): Promise<void> {
    await executeCommand(new Command<UpdateLicenseCommand>('UpdateLicenseCommand', command));
}

export async function deleteLicense(command: DeleteLicenseCommand): Promise<void> {
    await executeCommand(new Command<DeleteLicenseCommand>('DeleteLicenseCommand', command));
}
export async function deleteAccount(command: DeleteAccountCommand): Promise<void> {
    await executeCommand(new Command<DeleteAccountCommand>('DeleteAccountCommand', command));
}
export function getS3AvatarsQuery(query: GetS3AvatarsQuery): Promise<string[]> {
    return executeQuery(new Query<GetS3AvatarsQuery, string[]>('GetS3AvatarsQuery', query));
}

export async function sendSmsAppLinkCommand(command: SendSmsAppLinkCommand): Promise<void> {
    await executeCommand(new Command<SendSmsAppLinkCommand>('SendSmsAppLinkCommand', command));
}

export async function updateCellNumberLoginCommand(command: UpdateCellNumberLoginCommand): Promise<void> {
    await executeCommand(new Command<UpdateCellNumberLoginCommand>('UpdateCellNumberLoginCommand', command));
}
