import useAppLanguageStore from '@/settings/languages/AppLanguageStore';

export interface ValidationErrors {
    [key: string]: string[];
}

export class PortalError extends Error {
    public displayError?: string;
    public validationErrors?: ValidationErrors

    constructor(message: string, displayError?: string, validationErrors?: ValidationErrors) {
        super(message);
        if (displayError) {
            this.displayError = displayError;
        }
        this.validationErrors = validationErrors;
    }
}

export function getError(err: Error | string, defaultMsg: string = useAppLanguageStore().language.common.anErrorOccurred): string {
    if (typeof err === 'string') {
        return err;
    } else if (err instanceof PortalError && err.displayError) {
        return err.displayError;
    }
    // Let me log errors here - we need to log errors somewhere. If we were really ambitious, this could call a logging method on our API, AWS CloudWatch, etc.
    // tslint:disable-next-line
    return defaultMsg;
}
