import {DateTime} from 'luxon';
import useAppLanguageStore from '@/settings/languages/AppLanguageStore';

export function dateTimeFromString(date: string) {
    const languageStore = useAppLanguageStore();
    return DateTime.fromFormat(date, 'yyyy-MM-dd').setLocale(languageStore.language.language.locale);
}

export function isoToDateString(data: string) {
    return formatAsIsoDate(DateTime.fromISO(data));
}

export function dateTimeFromISO(date: string) {
    const languageStore = useAppLanguageStore();
    return DateTime.fromISO(date).setLocale(languageStore.language.language.locale).toFormat(languageStore.language.common.fullDateFormat);
}

export function abbreviatedDateFromString(date: string) {
    return abbreviatedDate(dateTimeFromString(date));
}

export function abbreviatedDate(date: DateTime) {
    const languageStore = useAppLanguageStore();
    return date.setLocale(languageStore.language.language.locale).toFormat(languageStore.language.common.fullDateFormat);
}

export function monthYearFromString(date: string) {
    if(!date) {
        return;
    }
    const [month, year] = date.split('/');
    return monthYear(dateTimeFromString(year + '-' + month + '-01'));
}

export function fromIsoToAbbreviatedDate(date: string) {
    return abbreviatedDate(DateTime.fromISO(date));
}

function monthYear(date: DateTime) {
    const languageStore = useAppLanguageStore();
    return date.setLocale(languageStore.language.language.locale).toFormat(languageStore.language.common.monthAndYear);
}

export function formatAsIsoDate(date: DateTime) {
    const languageStore = useAppLanguageStore();
    return date.setLocale(languageStore.language.language.locale).startOf('day').toFormat('yyyy-MM-dd');
}

export function getNotificationsDateForLocale(date: string) {
    const dateObject = DateTime.fromISO(date, {zone: 'utc'});
    const languageStore = useAppLanguageStore();
    return dateObject.toFormat(languageStore.language.notifications.dateFormat);
}

export const MAX_DATE_TIME = '9999-12-31T23:59:59.9999999';
