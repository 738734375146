import { defineStore } from 'pinia';
import {GetQuestionsForTodayResponse, Question} from '@/courses/module/questions/QuestionModel';
import {DateTime} from 'luxon';
import {alertController} from '@ionic/vue';
import {
    getAllQuestionsForToday,
    getTodaysQuestions,
    getUnlockedCourseModulesQuery
} from '@/courses/module/questions/QuestionService';
import {formatAsIsoDate} from '@/framework/formatting/DateFormatting';
import {shuffle} from '@/utils/utils';
import useAppLanguageStore from '@/settings/languages/AppLanguageStore';
import {ModuleType} from '@/courses/module/moduleModels';
import {CourseModuleModel} from '@/courses/CourseModel';
import useParticipantCourseModuleStore from "@/courses/ParticipantCourseModuleStore";

interface QuestionData {
    unansweredQuestions: Question[];
    questionsWithFeedback: Question[];
    allQuestions: Question[];
    dateRetrieved: string;
    nextDeliveryDate: string;
    selectedLanguageId: string;
    moduleType: ModuleType;
}

interface ModuleQuestions {
    [index: string]: QuestionData;
}

const useQuestionStore = defineStore('questionStore', {
    state: () => {
        const millisecondsInOneDay = 86400000;
        // Default to yesterday to force loading the questions from the API if we haven't yet
        const yesterday = DateTime.now().minus(millisecondsInOneDay);
        const dateRetrieved = formatAsIsoDate(yesterday);
        const unansweredQuestionKeys = Object.entries(localStorage)
            .flatMap(entry => entry[0])
            .filter(entry => entry.includes('unansweredQuestions'));
        const moduleIds = unansweredQuestionKeys.map(q => q.replace('unansweredQuestions-', ''));
        const data = {} as ModuleQuestions;
        for (const moduleId of moduleIds) {
            const questions = localStorage.getItem('unansweredQuestions-' + moduleId);            
            const questionsWithFeedback = localStorage.getItem('questionsWithFeedback-' + moduleId) ?? null;
            const allQuestions = localStorage.getItem('allQuestions-' + moduleId) ?? null;
            const date = localStorage.getItem('dateRetrieved-' + moduleId);
            const nextDeliveryDate = localStorage.getItem('nextDeliveryDate-' + moduleId) ?? '';
            const selectedLanguageId = localStorage.getItem('selectedLanguageId-' + moduleId) ?? '';
            const moduleType = Number(localStorage.getItem('moduleType-' + moduleId)) ?? ModuleType.Traditional;

            data[moduleId] = data[moduleId] ?? {
                unansweredQuestions: questions ? JSON.parse(questions) : [],
                questionsWithFeedback: questionsWithFeedback ? JSON.parse(questionsWithFeedback) : [],
                dateRetrieved: date ?? dateRetrieved,
                allQuestions: allQuestions ? JSON.parse(allQuestions) : [],
                nextDeliveryDate,
                selectedLanguageId,
                moduleType
            };
        }

        return data;
    },
    actions: {
        async showNoMoreQuestions(moduleId: string) {

            const unlockInfo = await getUnlockedCourseModulesQuery(moduleId);
            let message = '';
            if(unlockInfo.isComplete){
                message = useAppLanguageStore().language.questions.complete;
                if (unlockInfo.unlockedModuleNames?.length > 0) {
                    message = useAppLanguageStore().language.questions.congratulationsCompletionMessage;
                }
                await this.loadQuestions(moduleId);
            }
            else{
                const options = {
                    month: "long",
                    day: "numeric",
                };
                // @ts-ignore
                const internationalDate = new Intl.DateTimeFormat(useAppLanguageStore().language.language.locale, options).format(Date.parse(this[moduleId].nextDeliveryDate));
                message = useAppLanguageStore().language.questions.completeForToday.replace('{{ nextDeliveryDate }}', internationalDate);
            }

            // criteria on TAR-1023 - once questions completed for the day for a module, default back to preferred language
            localStorage.removeItem('selectedLanguageId-' + moduleId);
            this[moduleId].selectedLanguageId = '';
            
            const alert = await alertController.create({
                message: message,
                buttons: [useAppLanguageStore().language.common.ok.toUpperCase()]
            });
            await alert.present();
        },
        async loadAllQuestions(moduleIds: string[], languageId: string) {
            const allQuestions = await getAllQuestionsForToday(moduleIds, languageId);
            allQuestions.forEach((response: GetQuestionsForTodayResponse) => {
                this.setIfUndefined(response.moduleId, response.moduleType)
                this.setLocalStorageAndStore(response.moduleId, response);
            });
        },
        async loadQuestions(moduleId: string) {
            // if a module (course_module) is not active a 403 || 400 is returned
            // from the api in the get questions for today validator
            // only get questions for active modules
            const courseModules= useParticipantCourseModuleStore();
            await courseModules.loadParticipantCourseModules();
            const courseModule = courseModules.getCourseModuleModel(moduleId);
            if (!courseModule || !courseModule.isActive) {
                return;
            }
            await this.loadQuestionsForModule({moduleId: courseModule.moduleId,
                moduleType: courseModule.moduleType, isActive: courseModule.isActive} as CourseModuleModel
            );
        },
        async loadQuestionsForModule(courseModule: CourseModuleModel) {
            const moduleId = courseModule.moduleId;
            this[moduleId] = this[moduleId] ?? {
                unansweredQuestions: [],
                questionsWithFeedback: [],
                allQuestions: [],
                dateRetrieved: '',
                nextDeliveryDate: '',
                selectedLanguageId: '',
                moduleType: courseModule.moduleType
            };
            let response;
            try {
                // because we consolidated queries to be called from one place
                // we are not checking if the get questions should be called
                // some calls return a 403
                //TODO: update queries and logic so we can ignore in the future
                if (!courseModule.isActive || !moduleId) {
                    return;
                }
                response = await getTodaysQuestions(moduleId);
            } catch(e) {
                return;
            }
            this.setLocalStorageAndStore(moduleId, response);
        },
        setLocalStorageAndStore(moduleId: string, response: GetQuestionsForTodayResponse) {
            const nowString = formatAsIsoDate(DateTime.now());
            const questions = response.questions;
            for (const question of questions) {
                if (question.questionOption.randomizeOptionOrder) {
                    question.questionOption.responseOptions = shuffle(question.questionOption.responseOptions);
                }
                question.deliveredOn = nowString;
            }

            localStorage.setItem('questionsWithFeedback-' + moduleId, JSON.stringify(response.feedback));
            localStorage.setItem('unansweredQuestions-' + moduleId, JSON.stringify(questions));
            localStorage.setItem('allQuestions-' + moduleId, JSON.stringify(response.orderedQuestions));
            localStorage.setItem('dateRetrieved-' + moduleId, nowString);
            localStorage.setItem('nextDeliveryDate-' + moduleId, response.nextDeliveryDate);
            localStorage.setItem('moduleType-' + moduleId, response.moduleType + '');

            this[moduleId].unansweredQuestions = questions;
            this[moduleId].allQuestions = response.orderedQuestions;
            this[moduleId].questionsWithFeedback = response.feedback;
            this[moduleId].dateRetrieved = nowString;
            this[moduleId].nextDeliveryDate = response.nextDeliveryDate;
            this[moduleId].moduleType = response.moduleType;
        },
        async answerQuestion(questionId: string, moduleId: string) {
            const moduleQuestions = this[moduleId].unansweredQuestions.filter((q: Question) => q.questionOption.questionId !== questionId);
            this[moduleId].unansweredQuestions = moduleQuestions;
            await this.loadQuestions(moduleId);
            if (moduleQuestions.length) {
                localStorage.setItem('unansweredQuestions-' + moduleId, JSON.stringify(moduleQuestions));
            }
        },
        resetModule(moduleId: string) {
            localStorage.setItem('unansweredQuestions-' + moduleId, '[]');
            localStorage.setItem('questionsWithFeedback-' + moduleId, '[]');
            localStorage.setItem('allQuestions-' + moduleId, '[]');
            localStorage.setItem('dateRetrieved-' + moduleId, '');
            localStorage.setItem('nextDeliveryDate-' + moduleId, '');
            localStorage.setItem('moduleType-' + moduleId, ModuleType.Traditional + '');
            localStorage.removeItem('selectedLanguageId-' + moduleId);
            this[moduleId] = {
                unansweredQuestions: [],
                questionsWithFeedback: [],
                allQuestions: [],
                dateRetrieved: '',
                nextDeliveryDate: '',
                selectedLanguageId: '',
                moduleType: ModuleType.Traditional
            };
        },
        async setSelectedLanguage(moduleId: string, languageId: string) {
            localStorage.setItem('selectedLanguageId-' + moduleId, languageId);
            this[moduleId].selectedLanguageId = languageId;
            await this.loadQuestions(moduleId);
        },
        setIfUndefined(moduleId: string, moduleType: ModuleType) {
            this[moduleId] = this[moduleId] ?? {
                unansweredQuestions: [],
                questionsWithFeedback: [],
                allQuestions: [],
                dateRetrieved: '',
                nextDeliveryDate: '',
                selectedLanguageId: '',
                moduleType: moduleType
            };
        }
    },
    getters: {
        UnansweredQuestions: (state) => (moduleId: string) => {
            return state[moduleId]
                ? state[moduleId].unansweredQuestions
                : [];
        },
        HasNewQuestionsToday: (state) => (moduleId: string) => {
            return state[moduleId]
                ? state[moduleId].unansweredQuestions.some((q: Question) => q.deliveredOn === formatAsIsoDate(DateTime.now()))
                : false;
        },
        GetNextDeliveryDate:(state) => (moduleId: string) => {
            return state[moduleId].nextDeliveryDate
        },
        GetSelectedLanguage:(state) => (moduleId: string) => {
            return state[moduleId].selectedLanguageId;
        },
        GetModuleType:(state) => (moduleId: string) => {
            return state[moduleId].moduleType;
        },
        GetQuestionsWithFeedback:(state) => (moduleId: string) => {
            return state[moduleId]
                ? state[moduleId].questionsWithFeedback
                : [];
        },
        GetAllQuestions:(state) => (moduleId: string) => {
            return state[moduleId]
                ? state[moduleId].allQuestions
                : [];
        },
    }
});

export default useQuestionStore;