
import {
    IonApp, IonButtons,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle, IonModal,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    isPlatform,
} from '@ionic/vue';
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {
    archiveOutline,
    archiveSharp,
    bookmarkOutline,
    bookmarkSharp,
    heartOutline,
    heartSharp,
    mailOutline,
    mailSharp,
    paperPlaneOutline,
    paperPlaneSharp,
    trashOutline,
    trashSharp,
    warningOutline,
    warningSharp
} from 'ionicons/icons';
import useStore from '@/framework/auth/AuthStore';
import {
    ActionPerformed,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';
import useQuestionStore from '@/courses/module/questions/QuestionStore';
import {getWebNotificationsQuery} from "@/notifications/NotificationService";
import { toastController } from '@ionic/vue';
import {WebNotificationModel} from "@/notifications/NotificationModels";
import useAppLanguageStore from '@/settings/languages/AppLanguageStore';
import {enableInAppPurchasing} from '@/payment/MobilePaymentService';
import {getParticipantAppVersionQuery} from "@/appService";
import {APP_VERSION} from "@/framework/config/config";
import AvatarSelector from "@/profile/AvatarSelector.vue";
import {Browser} from "@capacitor/browser";
import useAuthenticationHelper from "@/framework/auth/AuthenticationComposable";

export default defineComponent({
    name: 'App',
    components: {
        IonButtons,
        IonModal, AvatarSelector,
        IonApp,
        IonContent,
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonListHeader,
        IonMenu,
        IonMenuToggle,
        IonNote,
        IonRouterOutlet,
        IonSplitPane,
    },
    data() {
        return {
            authStore: useStore(),
            intervalId: 0,
            showUpgradeModel: false,
            oneHourInSeconds: 3600000,
            versionCheckerIntervalId: 0
        }
    },
    setup() {
        const menuSide = ref('end');
        const authStore = useStore();
        const questionStore = useQuestionStore();
        const languageStore = useAppLanguageStore();
        
        const route = useRoute();
        const router = useRouter();
        const oneMinute = 60000;
        let toastingIntervalId = 0;

        authStore.$subscribe(
            (mutation: any, state: any) => {
                localStorage.setItem('pinia-a-state', JSON.stringify(state));
            },
            { detached: true }
        );

        const decideMenuSide= () => {
            menuSide.value = window.innerWidth > 991 ? 'start' : 'end';
        };

        onMounted(async () => {
            decideMenuSide();
            window.addEventListener('resize', decideMenuSide);
            
            if ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
                if (authStore.isLoggedIn) {
                    await enableInAppPurchasing();
                }
                // Leaving this here and commented out as a note so this isn't added back
                // we want push notifications to always show and not be silently ignored by the app, except for some exceptions
                // await PushNotifications.removeAllListeners();

                // Request permission to use push notifications
                // iOS will prompt user and return if they granted permission or not
                // Android will just grant without prompting
                PushNotifications.requestPermissions().then((result: any) => {
                    if (result.receive === 'granted') {
                        // Register with Apple / Google to receive push via APNS/FCM
                        PushNotifications.register();
                    }
                });

                // On success, we should be able to receive notifications
                PushNotifications.addListener('registration',
                    (token: Token) => {
                        // store this be sent during login to validate we have the most up-to-date token for device
                        localStorage.setItem('notification-token', token.value);
                    }
                );

                // Method called when tapping on a notification
                PushNotifications.addListener('pushNotificationActionPerformed',
                    async (notification: ActionPerformed) => {
                        const data = notification.notification.data;
                        if (data.moduleId) {
                            await questionStore.loadQuestions(data.moduleId);
                            if (questionStore.HasNewQuestionsToday(data.moduleId)) {
                                await router.push({name: 'module', params: {id: data.moduleId}});
                            } else {
                                // we need this event when a user is already on the Dashboard
                                // they won't get the alert popup because they won't be navigating there
                                const pushNotificationEvent = new CustomEvent('pushNotificationClicked', {detail: data.moduleId});
                                window.dispatchEvent(pushNotificationEvent);
                                await router.push({name: 'dashboard', query: {module: data.moduleId}});
                            }
                        }
                    }
                );
            }
        });

        async function toasting() {
            //long poll solution - for all platforms, when push notifications are off these are still required to be displayed
            let notifications = [] as WebNotificationModel[];

            if (authStore.isLoggedIn) {
                notifications = await getWebNotificationsQuery();
                if (notifications.length) {
                    await sendToast();
                }
            }

            async function sendToast() {
                const message = notifications.shift();
                if (message) {
                    const toast = await toastController.create({
                        message: message.description,
                        duration: 2500,
                        position: 'top',
                        cssClass: 'custom-toast',
                    });
                    await toast.present();
                    // onDidDismiss resolves after and toast disappears,
                    // so we can try sending another if there are multiple notifications,
                    // otherwise they stack and are not visible
                    await toast.onDidDismiss();
                    await sendToast();
                }
            }
            if (notifications.length) {
                await sendToast();
            }
        }
        toastingIntervalId = setInterval(toasting, oneMinute);

        /**
         * This version checking only works with semantic versions, x.x.x, see https://semver.org/ for more info
         */


        onUnmounted(()=>{
            window.removeEventListener('resize', decideMenuSide)
            PushNotifications.removeAllListeners();
            clearInterval(toastingIntervalId);
        });
        
        const itemClicked = (i: any) => {
            if (i.url === router.currentRoute.value.path) {
                window.location.reload();
            }
        };

        return {
            router,
            archiveOutline,
            archiveSharp,
            bookmarkOutline,
            bookmarkSharp,
            heartOutline,
            heartSharp,
            mailOutline,
            mailSharp,
            paperPlaneOutline,
            paperPlaneSharp,
            trashOutline,
            trashSharp,
            warningOutline,
            warningSharp,
            menuSide,
            itemClicked,
            isSelected: (url: string) => url === route.path ? 'selected' : '',
            languageStore
        }
    },
    mounted() {
        this.versionChecker();
        this.versionCheckerIntervalId = setInterval(this.versionChecker, this.oneHourInSeconds);
    },
    unmounted() {
        clearInterval(this.versionCheckerIntervalId);
    },
    computed: {
        direction() {
            return this.router.currentRoute.value.name === 'module' ? this.languageStore.questionLanguage.textDirection : this.languageStore.language.language.textDirection;
        },
        hideMenu() {
            const hideForRoutes = ['/register', '/login', '/forgot-password', '/forgot-password-code'];
            return hideForRoutes.includes(this.$router.currentRoute.value.path);
        },
        pwaHide() {
            if((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
                return false;
            } else {
                return this.hideMenu;
            }
        },
        isMobile() {
            return ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb'));
        },
        outdatedAppVersion() {
            if((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
                return this.languageStore.language.appVersion.pleaseUpdateMobile;
            } else {
                return this.languageStore.language.appVersion.pleaseUpdateBrowser;
            }
        },
        appPages() {
           return [
                {
                    title: this.languageStore.language.menu.dashboard,
                    url: '/dashboard',
                    line: 'none',
                    hideMenu:false
                },
                {
                    title: this.languageStore.language.menu.myCourses,
                    url: '/my-courses',
                    line: 'none',
                    hideMenu:false
                },
                {
                    title: this.languageStore.language.menu.allCourses,
                    url: '/courses',
                    line: 'none',
                    hideMenu:false
                },
                {
                    title: this.languageStore.language.menu.myProfile,
                    url: '/my-profile',
                    line: 'none',
                    hideMenu:false
                },
                {
                    title: this.languageStore.language.menu.notifications,
                    url: '/notifications',
                    line: 'none',
                    hideMenu:false
                },
                {
                    title: this.languageStore.language.menu.register,
                    url: '/register',
                    line: 'none',
                    hideMenu:true
                },
                {
                    title: this.languageStore.language.menu.login,
                    url: '/login',
                    line: 'none',
                    hideMenu:true
                },
               {
                   title: this.languageStore.language.menu.featurebase,
                   url: '/featurebase',
                   line: 'none',
                   hideMenu:false
               }
            ];
        }
    },
    methods: {
        async logout() {
            const {gotoLogout} = useAuthenticationHelper();
            if (this.authStore.isLoggedIn) {
                this.authStore.logoutUser();
                gotoLogout();
            }
            this.$router.replace({name: 'login'});
            if (this.isMobile) {
                if (isPlatform('android')) {
                    await Browser.open({ url: 'https://play.google.com/store/apps/details?id=com.medobby.lrn' });
                }

                if (isPlatform('ios')) {
                    await Browser.open({ url: 'itms-apps://itunes.apple.com/app/id6444162110/' });
                }
            }
        },
        closeUpgradeModel() {
            this.showUpgradeModel = false;
            this.logout();
        },
        async versionChecker() {
            function isIncompatibleVersion(appVersion: string, apiVersion: string): boolean {
                const splitAppVersion = appVersion.split('.');
                const splitApiVerion = apiVersion.split('.');

                if (splitAppVersion.length !== 3 || splitAppVersion.length !== splitApiVerion.length) {
                    throw new Error("Incorrect App Version.")
                }

                for (let index = 0; index < 3; index++)         {
                    const isLowerVersion = parseInt(splitAppVersion[index], 10) < parseInt(splitApiVerion[index], 10);
                    if (isLowerVersion) {
                        return true;
                    }
                }
                return false;
            }

            const apiExpectedParticipantAppVersion = await getParticipantAppVersionQuery();
            if (isIncompatibleVersion(APP_VERSION.trim(), apiExpectedParticipantAppVersion.version.trim())) {
                this.showUpgradeModel = true;
            }
        }
    }
});
