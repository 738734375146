import {isPlatform} from "@ionic/vue";

export const APPLE_APP_STORE_URL = process.env.VUE_APP_APPLE_APP_STORE_URL || 'https://apps.apple.com/us/app/the-learnery/id6444162110';
export const ANDROID_PLAY_STORE_URL = process.env.VUE_APP_ANDROID_PLAY_STORE_URL || 'https://play.google.com/store/apps/details?id=com.medobby.lrn';

export const BASE_S3_URL = process.env.VUE_APP_BASE_S3_URL || '';
export const IS_MOBILE = ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb'));
export const APP_BRANCH = process.env.VUE_APP_BRANCH || '';
export const APP_VERSION = process.env.VUE_APP_VERSION || '#.#.#';
export const APP_REDIRECT_URL = process.env.VUE_APP_REDIRECT_URL;
export const VUE_APP_TWILIO_PHONE = process.env.VUE_APP_TWILIO_PHONE;