import {
    getAllGeneralAvailableProductsQuery,
    mobileCreateOrderCommand,
    MobileCreateOrderCommand,
    ProductSummary
} from "@/courses/CourseService";
import 'cordova-plugin-purchase/www/store';
import IRegisterProduct = CdvPurchase.IRegisterProduct;
import Platform = CdvPurchase.Platform;
import usePurchaseStore from '@/payment/PurchaseStore';
import {isPlatform} from "@ionic/vue";

export async function enableInAppPurchasing() {
    const products = await getAllGeneralAvailableProductsQuery() as ProductSummary[];
    const {store, ProductType, Platform} = CdvPurchase;
    const purchaseStore = usePurchaseStore();
    purchaseStore.clearPurchases();
    const productsToRegister = products.map(product => { return {id: String(product.sku), type: ProductType.CONSUMABLE, platform: isPlatform('android') ? Platform.GOOGLE_PLAY : Platform.APPLE_APPSTORE} as IRegisterProduct});
    store.register(productsToRegister);
    store.initialize([isPlatform('android') ? Platform.GOOGLE_PLAY : Platform.APPLE_APPSTORE]).then(() => store.update().then(() => store.restorePurchases()))
        .catch((e) => console.error(e));
    store.when()
        .approved(async transaction => {
            const product = store.get(transaction.products[0].id);
            const orderId = purchaseStore.purchases.get(product?.id.toString());
            // This "if" is here because the approved() listener is being executed twice in a row. Can't for the life of me
            // figure out why. If the order isn't found, we've processed it already, so ignore
            if (orderId && product) {
                const order = {
                    sku: product.id,
                    orderId: orderId,
                    price: parseFloat(product.pricing?.price.replace('$', '') ?? '0')
                } as MobileCreateOrderCommand;
                await mobileCreateOrderCommand(order);
                purchaseStore.removeProductFromOrder(product.id);
                const event = new Event('purchaseMade');
                window.dispatchEvent(event);
                //p.finish(); //TODO: TAR-840, TAR-841 can not keep finish here it needs to be in the verified section along with finishing the order
            }
            await transaction.verify();
        })
        //TODO: TAR-840, TAR-841 need to create verifications for receipts
        .verified(receipt => receipt.finish());
}