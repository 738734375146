// https://www.itcodar.com/javascript/javascript-shuffle-html-list-element-order.html
export function shuffle(array: any) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        const random = array[randomIndex];
        const current = array[currentIndex];
        array[currentIndex] = random;
        array[randomIndex] = current;
    }

    return array;
}