import { defineStore } from 'pinia';
import {getParticipantCourseModulesQuery} from "@/courses/CourseService";
import {CourseDetailModel, CourseModuleModel} from "@/courses/CourseModel";

const useParticipantCourseModuleStore = defineStore('participantCourseModuleStore', {
    state: () => {
        return {
            courseModuleModels: new Map<string, CourseModuleModel>(),
            courseDetailModels: [] as CourseDetailModel[],
        };
    },
    actions: {
        async loadParticipantCourseModules() {
            if (!this.courseDetailModels.length) {
                await this.reloadParticipantCourseModules();
            }
        },
        async reloadParticipantCourseModules() {
            const results = await getParticipantCourseModulesQuery();
            this.courseDetailModels = results;
            for (const result of results.flatMap(result => result.courseModuleModels)) {
                if (result.started) {
                    this.courseModuleModels.set(result.moduleId, result);
                }
            }
        },
        async setModuleActive(moduleId: string) {
            const courseModule = this.courseModuleModels.get(moduleId);
            if (courseModule) {
                courseModule.isActive = true;
                this.courseModuleModels.set(moduleId, courseModule);
            }
        }
    },
    getters: {
        getCourseModuleModel: (state) => (moduleId: string) => {
            return state.courseModuleModels.get(moduleId);
        },
        getCourseDetailModels:(state) => {
            return state.courseDetailModels;
        }
    }
});

export default useParticipantCourseModuleStore;
