import {Command, executeCommand, executeQuery, Query} from '@/framework/http/CqrsService';
import {GetAllQuestionsForTodayResponse, GetQuestionsForTodayResponse} from '@/courses/module/questions/QuestionModel';
import useAuthStore from '@/framework/auth/AuthStore';
import {QuestionFeedback} from '@/courses/module/questions/FeedbackModel';
import {httpPost} from '@/framework/http/HttpServices';
import {CourseModuleModel} from "@/courses/module/questions/CourseModuleModel";
import {UnlockedCourseModulesModel} from "@/courses/CourseModel";
import useQuestionStore from '@/courses/module/questions/QuestionStore';

interface GetQuestionsForTodayQuery {
    userId: string;
    moduleId: string;
    languageId: string;
}

interface GetCourseModuleQuery {
    moduleId: string;
}

interface GetUnlockedCourseModulesQuery {
    courseModuleId: string;
}

export interface CreateReferenceLinkTrackingCommand {
    linkTextMapId: string;
    moduleId: string;
    questionId: string;
}
export interface SetCourseModuleCompletedCommand {
    userId: string;
    moduleId: string;
    courseId: string;
}

export interface GetAllQuestionsForTodayQuery {
    moduleIds: string[];
    languageId: string;
}


export function getTodaysQuestions(moduleId: string): Promise<GetQuestionsForTodayResponse> {
    const authStore = useAuthStore();
    const questionStore = useQuestionStore();
    return executeQuery(new Query<GetQuestionsForTodayQuery, GetQuestionsForTodayResponse>('GetQuestionsForTodayQuery', 
        { userId: authStore.user.id, 
            moduleId, 
            languageId: questionStore.GetSelectedLanguage(moduleId) !== '' ? questionStore.GetSelectedLanguage(moduleId) : authStore.user.defaultLanguageId }));
}

export function getAllQuestionsForToday(moduleIds: string[], languageId: string ): Promise<GetAllQuestionsForTodayResponse[]> {
    return executeQuery(new Query<GetAllQuestionsForTodayQuery, GetAllQuestionsForTodayResponse[]>('GetAllQuestionsForTodayQuery',
        {
            moduleIds,
            languageId
        }));
}




export function generateFeedback(moduleId: string, responseOptionIds: string[], questionId: string, questionQueueId: string ): Promise<QuestionFeedback> {
    const authStore = useAuthStore();
    const questionStore = useQuestionStore();
    return httpPost('question-feedback', { 
        userId: authStore.user.id, 
        moduleId, 
        responseOptionIds, 
        questionId,
        languageId: questionStore.GetSelectedLanguage(moduleId) !== '' ? questionStore.GetSelectedLanguage(moduleId) : authStore.user.defaultLanguageId,
        questionQueueId: questionQueueId}) as Promise<QuestionFeedback>;
}

export function getCourseModuleQuery(moduleId: string): Promise<CourseModuleModel> {
    return executeQuery(new Query<GetCourseModuleQuery, CourseModuleModel>('GetCourseModuleQuery', { moduleId }));
}

export function getUnlockedCourseModulesQuery(courseModuleId: string): Promise<UnlockedCourseModulesModel> {
    return executeQuery(new Query<GetUnlockedCourseModulesQuery, UnlockedCourseModulesModel>('GetUnlockedCourseModulesQuery', { courseModuleId }));
}

export async function createReferenceLinkTrackingCommand(command: CreateReferenceLinkTrackingCommand): Promise<void> {
    await executeCommand(new Command<CreateReferenceLinkTrackingCommand>('CreateReferenceLinkTrackingCommand', command));
}

export async function setCourseModuleCompletedCommand(command: SetCourseModuleCompletedCommand): Promise<void> {
    await executeCommand(new Command<SetCourseModuleCompletedCommand>('SetCourseModuleCompletedCommand', command));
}