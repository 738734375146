import {httpGet} from "@/framework/http/HttpServices";

export interface AppVersion {
    version: string;
}
export async function getParticipantAppVersionQuery(): Promise<AppVersion> {
    const appVersion = await httpGet('app/version') as AppVersion;
    return {
        version: appVersion.version
    } as AppVersion;
}