import type { BasicUser } from '@/framework/auth/BasicAuthUser';
import { PortalError } from '@/framework/errors/PortalError';
import {Command, executeCommand, executeQuery, Query} from '@/framework/http/CqrsService';
import { httpPost } from '../http/HttpServices';
import useAuthenticationHelper from "@/framework/auth/AuthenticationComposable";

interface BasicUserQuery {}
interface SignedUrlPolicyQuery {}
interface LanguagesSignedUrlPolicyQuery {}

interface SignInQuery {
    username: string;
    password: string;
}
export interface SignInQueryModel {
    authToken: string;
    idToken: string;
    expiresIn: number;
    challengeRequired: boolean;
    changePasswordSession: string;
}

interface ResetUserPasswordCommand {
    email: string;
    languageId: string;
}

export interface SignedUrlPolicyModel {
    policy: string;
}

interface ConfirmForgotPasswordCommand {
    id: string;
    languageId: string;
    password: string;
    repeatPassword: string;
    code: string;
}

interface LogoutCommand {}

export function getCurrentUser(): Promise<BasicUser | undefined> {
    return executeQuery(new Query<BasicUserQuery, BasicUser | undefined>('BasicUserQuery', {}));
}

export function signedCookieCommand(): Promise<void> {
    return executeCommand(new Command('SignedCookieCommand', {}));
}

export function signedUrlPolicyQuery(): Promise<SignedUrlPolicyModel> {
    return executeQuery(new Query<SignedUrlPolicyQuery, SignedUrlPolicyModel>('SignedUrlPolicyQuery', {}));
}

export function languagesSignedUrlPolicyQuery(): Promise<SignedUrlPolicyModel> {
    return executeQuery(new Query<LanguagesSignedUrlPolicyQuery, SignedUrlPolicyModel>('LanguagesSignedUrlPolicyQuery', {}));
}

export async function exchangeCodeForToken(code: string): Promise<{ id_token: string, expires_in: number, access_token: string }> {
    const {getAppClientId, getRedirectUrl, getBaseOauthUrl, CODE_VERIFIER_KEY} = useAuthenticationHelper();
    const body = new URLSearchParams();
    body.append('grant_type', 'authorization_code');
    body.append('code', code);
    body.append('client_id', getAppClientId());
    body.append('redirect_uri', getRedirectUrl());
    body.append('code_verifier', sessionStorage.getItem(CODE_VERIFIER_KEY) as any as string);

    const response = await fetch(`${getBaseOauthUrl()}/token`, { method: 'POST', body });
    if (!response.ok) {
        throw new PortalError(await response.text());
    }
    return response.json();
}

export function addTokenCommand(token: string): Promise<void> {
    return executeCommand(new Command('AddDeviceTokenCommand', {Token: token}));
}

export function confirmForgotPasswordCommand(command: ConfirmForgotPasswordCommand): Promise<void> {
    return executeCommand(new Command('ConfirmForgotPasswordCommand', command));
}

export function resetUserPasswordCommand(command: ResetUserPasswordCommand): Promise<string> {
    return httpPost('forgot-password', command) as Promise<string>;
}

export function getLoginCode(username:string, password:string):Promise<SignInQueryModel> {    
    return executeQuery(new Query<SignInQuery, SignInQueryModel>('SignInQuery', {'username':username, 'password':password}));    
}

export function getAuthToken(cognitoIdToken: string): Promise<{ token: string } | undefined> {
    return httpPost('logged-in', { cognitoIdToken });
}

export function logoutCommand() {
    return executeCommand(new Command<LogoutCommand>('LogoutCommand', {}));
}