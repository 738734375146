export enum LanguageIds {
    English = 'fef5bb0f-b0fb-4cd0-bf0d-c0f1740f4506',
    Hebrew = 'ce7fcf65-d986-4e18-9637-91a13e0e27d8',
    Arabic = '5644d3b5-5ccc-4c76-a73e-a7a800300c4b',
    Portuguese = '4ac3118f-5b79-4965-b748-5b614e892bde',
    Spanish = '7070a077-0326-4f61-aa1f-0d0beac31f2c',
    French = 'c0dbbf67-9121-4e34-9fc9-b2fa5c53ea03',
    Russian = 'c0f61375-9e7a-4640-88c4-3a92fc7095dc'
}

export enum LanguageFiles {
    English = 'english.json',
    Hebrew = 'hebrew.json',
    Arabic = 'arabic.json',
    Portuguese = 'portuguese.json',
    Spanish = 'spanish.json',
    French = 'french.json',
    Russian = 'russian.json'
}

export function getLanguageForLocale() {
    const language = navigator.language.split('-')[0];
    switch (language) {
        case 'en':
            return LanguageFiles.English;
        case 'es':
            return LanguageFiles.Spanish;
        case 'he':
            return LanguageFiles.Hebrew;
        case 'iw':
            return LanguageFiles.Hebrew;
        case 'ar':
            return LanguageFiles.Arabic;
        case 'pt':
            return LanguageFiles.Portuguese;
        case 'fr':
            return LanguageFiles.French;
        case 'ru':
            return LanguageFiles.Russian;
        default:
            return LanguageFiles.English;
    }
}