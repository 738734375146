export interface ModuleDetails {
    id: string;
    name: string;
    description: string;
    questionCount: number;
    percentComplete: number;
}

export interface ModuleDetailModel {
    id: string;
    name: string;
    description: string;
    isActive: string;
    projectId: string;
}

export enum ModuleType {
    Traditional = 0,
    FreeRoam = 1,
    Review
}

export interface QuestionModuleOrderModel {
    questionId: string;
    sortOrder: number;
}