import {executeQuery, Query} from '@/framework/http/CqrsService';
import {Country} from '@/components/countryRegionSelect/CountryRegionModel';

export interface GetCountriesQuery {
    languageId: string;
}

interface GetTimezonesQuery {}

export function getCountries(query: GetCountriesQuery): Promise<Country[]> {
    return executeQuery(new Query<GetCountriesQuery, Country[]>('GetCountriesQuery', query));
}

export function getTimezonesQuery(): Promise<number[]> {
    return executeQuery(new Query<GetTimezonesQuery, number[]>('GetTimezonesQuery', {}));
}