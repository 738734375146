import { PortalError, type ValidationErrors } from '../errors/PortalError';
import useAuthStore from '../auth/AuthStore';
import useAppLanguageStore from '@/settings/languages/AppLanguageStore';
import {toastController} from "@ionic/vue";

enum HttpMethod {
    POST = 'POST',
    PUT = 'PUT',
    GET = 'GET',
    DELETE = 'DELETE'
}

// As use cases arise of other HTTP Response Statuses containing a body, they can be added to this list.
const ResponsesWithBody = new Set<number>([200]);
// Routes where 401 responses shouldn't redirect to login because they're involved in the login flow.
const LoginPaths = new Set<string>(['/logged-in','/register', '/login', '/forgot-password', '/forgot-password-code']);

export function httpPost<T>(url: string, data = {}): Promise<T | undefined> {
    const options = { body: JSON.stringify(data) } as RequestInit;
    return makeRequest(url, HttpMethod.POST, options) as Promise<T>;
}

export function httpPostFile<T>(url: string, data: FormData): Promise<T | undefined> {
    return makeRequest(url, HttpMethod.POST, { headers: headers(true), body: data }) as Promise<T>;
}

export async function httpPut<T>(url: string, data = {}): Promise<void> {
    await makeRequest(url, HttpMethod.PUT, { body: JSON.stringify(data) });
}

export function httpGet<T>(url: string, blobResponse = false): Promise<T> {
    return makeRequest(url, HttpMethod.GET, {}, blobResponse) as Promise<T>;
}

export async function httpGetBlob<T>(url: string, blobResponse = false): Promise<{ blob: Blob, headers: Headers } | undefined> {
    const response = await makeRequest(url, HttpMethod.GET, {}, blobResponse);
    if (response && response instanceof Response) {
        const blob = await response.blob();
        return { blob, headers: response.headers };
    } else {
        throw new PortalError(useAppLanguageStore().language.common.invalidResponse);
    }
}

export async function httpDelete(url: string): Promise<void> {
    await makeRequest(url, HttpMethod.DELETE);
}

async function makeRequest<T>(url: string, method: HttpMethod, options: RequestInit = {}, blobResponse = false): Promise<T | undefined | Response> {
    const fetchUrl = process.env.VUE_APP_BASE_API_URL + url;
    const credentialType = 'include';
    const response = await fetch(fetchUrl, {
        method: method,
        headers: headers(),
        mode: 'cors',
        credentials: credentialType,
        ...options
    });

    if (ResponsesWithBody.has(response.status)) {
        if (blobResponse) {
            return response;
        }
        return response.json();
    } else if (!response.ok) {
        throw await buildErrorForResponse(response);
    }
}

function headers(isFileUpload: boolean = false) {
    const allHeaders: Record<string, string> = {
        Accept: 'application/json, */*',
    };

    if (!isFileUpload) {
        allHeaders['Content-Type'] = 'application/json';
    }

    const authStore = useAuthStore();

    const token = authStore.token;
    if (token) {
        allHeaders.Authorization = `Bearer ${token}`;
    }
    return allHeaders;
}

async function buildErrorForResponse(response: Response) {
    const languageStore = useAppLanguageStore();
    let error = languageStore.language.common.anErrorOccurred;
    let errorDetails = undefined as ValidationErrors | undefined;
    if (response.status === 401 && !LoginPaths.has(window.location.pathname)) {
        const toast = await toastController.create({
            message: languageStore.language.common.sessionExpired,
            duration: 2500,
            position: 'top',
            cssClass: 'custom-toast',
        });
        await toast.present();
        // onDidDismiss resolves after and toast disappears,
        // so we can try sending another if there are multiple notifications,
        // otherwise they stack and are not visible
        await toast.onDidDismiss();
        const authStore = useAuthStore();
        // stop login redirect to dashboard loop
        await authStore.logoutUser();
        window.location.href = '/login';
    } else if (response.status === 403) {
        error = languageStore.language.common.forbidden;
    } else if (response.status === 400) {
        const { title, errors } = await response.json();
        error = title;
        if (errors) {
            errorDetails = errors;
        }
    } else {
        error = '';
    }
    return new PortalError(error, error, errorDetails);
}