import useStore from '@/framework/auth/AuthStore';
import { BASE_S3_URL } from '@/framework/config/config';

export function useImageMixin() {
    const authStore = useStore();

    function getImageUrl(imagePath: string) {
        return imagePath ? `${BASE_S3_URL}${imagePath}${authStore.imagePolicyQueryString}` : '';
    }

    return { getImageUrl };
}
