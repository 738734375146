import {Command, executeCommand, executeQuery, Query} from '@/framework/http/CqrsService';
import { SpokenLanguageModel } from "@/settings/languages/SpokenLanguageModel";
import {AppLanguageModel} from '@/settings/languages/AppLanguageModel';

export interface GetSpokenLanguagesQuery {}


export interface AssignLanguageToUserDetailsCommand {
    userId: string;
    languageId: string;
}

export interface RemoveLanguageFromUserDetailsCommand {
    userId: string;
    languageId: string;
}

export interface GetAvailableLanguagesForQuestionQuery {
    questionId: string;
}

export interface GetAvailableLanguagesForReviewQuestionQuery {
    questionId: string;
}

export function getSpokenLanguagesQuery(query: GetSpokenLanguagesQuery): Promise<SpokenLanguageModel[]> {
    return executeQuery(new Query<GetSpokenLanguagesQuery, SpokenLanguageModel[]>('GetSpokenLanguagesQuery', query));
}

export async function assignLanguageToUserDetailsCommand(command: AssignLanguageToUserDetailsCommand): Promise<void> {
    await executeCommand(new Command<AssignLanguageToUserDetailsCommand>('AssignLanguageToUserDetailsCommand', command));
}

export async function removeLanguageFromUserDetailsCommand(command: RemoveLanguageFromUserDetailsCommand): Promise<void> {
    await executeCommand(new Command<RemoveLanguageFromUserDetailsCommand>('RemoveLanguageFromUserDetailsCommand', command));
}

export function getAppLanguagesQuery(): Promise<AppLanguageModel[]> {
    return executeQuery(new Query('GetAppLanguagesQuery', {}));
}

export function getAvailableLanguagesForQuestionQuery(query: GetAvailableLanguagesForQuestionQuery): Promise<AppLanguageModel[]> {
    return executeQuery(new Query<GetAvailableLanguagesForQuestionQuery, AppLanguageModel[]>('GetAvailableLanguagesForQuestionQuery', query));
}

export function getAvailableLanguagesForReviewQuestionQuery(query: GetAvailableLanguagesForReviewQuestionQuery): Promise<AppLanguageModel[]> {
    return executeQuery(new Query<GetAvailableLanguagesForReviewQuestionQuery, AppLanguageModel[]>('GetAvailableLanguagesForReviewQuestionQuery', query));
}

