import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { App } from '@capacitor/app';
import {URLOpenListenerEvent} from "@capacitor/app";
import {isPlatform} from "@ionic/vue";
import {registerRouterAuthChecks} from "@/framework/auth/AuthRouting";
import { APP_REDIRECT_URL } from '@/framework/config/config';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login',
        meta: { public: true }
    },
    {
        path: '/email/email-confirmation',
        name: 'email-confirmation',
        component: () => import('@/email/EmailConfirmation.vue'),
        meta: {public: true}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/login/Login.vue'),
        meta: { public: true }
    },
    {
        path: '/logged-in',
        name: 'logged-in',
        component: () => import('@/framework/auth/LoggedIn.vue'),
        meta: { public: true }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: { public: false },
        component: () => import('@/dashboard/Dashboard.vue')
    },
    {
        path: '/my-courses',
        name: 'my-courses',
        props: true,
        component: () => import('@/views/MyCourses.vue')
    },
    {
        path: '/course/:courseId',
        name: 'course',
        component: () => import('@/courses/Course.vue'),
        props: true
    },
    {
        path: '/courses',
        name: 'courses',
        component: () => import('@/courses/Courses.vue')
    },
    {
        path: '/module/:id',
        name: 'module',
        component: () => import('@/courses/module/Module.vue'),
        props: true
    },
    {
        path: '/review-module/:userReviewModuleId',
        name: 'review-module',
        component: () => import('@/courses/reviewModule/ReviewModule.vue'),
        props: true
    },
    {
        path: '/my-profile',
        name: 'my-profile',
        component: () => import('@/profile/MyProfile.vue')
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/notifications/Notifications.vue')
    },
    {
        path: '/payment/:status',
        name: 'payment-status',
        component: () => import('@/payment/PaymentComplete.vue'),
        props: true
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/registration/Registration.vue'),
        meta: { public: true }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/login/ForgotPassword.vue'),
        meta: { public: true }
    },
    {
        path: '/forgot-password-code',
        name: 'forgot-password-code',
        component: () => import('@/login/ForgotPasswordCode.vue'),
        meta: { public: true }
    },
    {
        path: '/new-user',
        name: 'new-user',
        component: () => import('@/userPasswordChange/SetNewPassword.vue'),
        meta: { public: true },
        props: true
    },
    {
        path: '/featurebase',
        name: 'featurebase',
        component: () => import('@/featurebase/sso.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

App.addListener('appUrlOpen', async function (event: URLOpenListenerEvent) {
    // Example url: aristotle://logged-in?code=bla-bla-bla
    // slug = /logged-in?code=bla-bla-bla
    // if a slug is not present then the app will not redirect to any page
    const slug = event.url.split('aristotle:/').pop();

    if((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
        if (event.url.startsWith(APP_REDIRECT_URL)) {
            await router.push({
                name: 'dashboard'
            });
        } else if (slug && slug.includes('logged-in')) {
            const slugPath = slug.split('?')[0];
            const slugQueryString = slug.split('?')[1];
            const queries = slugQueryString.split('&');
            const code = queries[0].split('=').pop();
            const state = queries[1].split('=').pop();

            // this should be coming back from cognito login
            if (slugPath && state && code) {
                await router.push({
                    path: slugPath, query: {code: code, state: state}
                });
            }
        } else if (slug) {
            await router.push({
                path: slug,
            });
        }
    } else {
        if (slug) {
            await router.push({
                path: slug,
            });
        }
    }
});

export default router
registerRouterAuthChecks(router);