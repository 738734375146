import {defineStore} from 'pinia';
import {AppLanguageModel} from '@/settings/languages/AppLanguageModel';
import {getAppLanguagesQuery} from '@/settings/languages/LanguageService';
import {APP_VERSION, BASE_S3_URL} from '@/framework/config/config';
import useAuthStore from "@/framework/auth/AuthStore";
import {getLanguageForLocale, LanguageFiles, LanguageIds} from '@/settings/languages/LanguageConstants';
import useCountryStore from "@/components/countryRegionSelect/CountryStore";

const useAppLanguageStore = defineStore('appLanguageStore', {
    state: () => {
        const language = localStorage.getItem('language');
        const questionLanguage = localStorage.getItem('question-language');
        return {
            appLanguages: [] as AppLanguageModel[],
            language: language ? JSON.parse(language) : {},
            questionLanguage: questionLanguage ? JSON.parse(questionLanguage) : {}
        };
    },

    actions: {
        async loadAppLanguages() {
            this.appLanguages = (await getAppLanguagesQuery());
        },
        async loadLanguage(languageId: string) {
            if (!useAuthStore().languagesPolicyQueryString  || !useAuthStore().isLoggedIn) {
                await useAuthStore().loadLanguagesPolicyQueryString();
            }
            await fetch(`${BASE_S3_URL}/mobile_languages/${getFile(languageId)}${useAuthStore().languagesPolicyQueryString}&App-Version=${APP_VERSION}`)
                .then(response => {
                    return response.json();
                })
                .then(async jsondata => {
                    if (languageId == LanguageIds.Arabic) {
                        document.getElementsByTagName("html")[0].classList.add('arabic-font-size');
                    } else {
                        document.getElementsByTagName("html")[0].classList.remove('arabic-font-size');
                    }
                    
                    this.language = jsondata;
                    localStorage.setItem('language', JSON.stringify(jsondata));
                    await useCountryStore().loadTimezones(true);
                    this.questionLanguage = {
                        selectAll: jsondata.questions.selectAll,
                        correctAnswer: jsondata.questions.correctAnswer,
                        incorrectAnswer: jsondata.questions.incorrectAnswer,
                        explanation: jsondata.questions.explanation,
                        references: jsondata.questions.references,
                        submit: jsondata.common.submit,
                        nextquestion: jsondata.questions.nextquestion,
                        correctPercent: jsondata.questions.correctPercent,
                        locale: jsondata.language.locale,
                        questionResent: jsondata.questions.questionResent,
                        noAnswerSelected: jsondata.questions.noAnswerSelected,
                        errorStartingModule: jsondata.questions.errorStartingModule,
                        oops: jsondata.questions.oops,
                        forbidden: jsondata.common.forbidden,
                        textDirection: jsondata.language.textDirection,
                        days: jsondata.questions.days,
                        next: jsondata.questions.next,
                        previous: jsondata.questions.previous
                    };
                    localStorage.setItem('question-language', JSON.stringify(this.questionLanguage));
                });
        },
        async loadLanguageForQuestion(languageId: string) {
            await fetch(`${BASE_S3_URL}/mobile_languages/${getFile(languageId)}${useAuthStore().languagesPolicyQueryString}`)
                .then(response => {
                    return response.json();
                })
                .then(async jsondata => {
                    if (languageId == LanguageIds.Arabic) {
                        document.getElementsByTagName("html")[0].classList.add('question-arabic-font-size');
                    } else {
                        document.getElementsByTagName("html")[0].classList.remove('question-arabic-font-size');
                    }
                    this.questionLanguage = {
                        selectAll: jsondata.questions.selectAll,
                        correctAnswer: jsondata.questions.correctAnswer,
                        incorrectAnswer: jsondata.questions.incorrectAnswer,
                        explanation: jsondata.questions.explanation,
                        references: jsondata.questions.references,
                        submit: jsondata.common.submit,
                        nextquestion: jsondata.questions.nextquestion,
                        correctPercent: jsondata.questions.correctPercent,
                        locale: jsondata.language.locale,
                        questionResent: jsondata.questions.questionResent,
                        noAnswerSelected: jsondata.questions.noAnswerSelected,
                        errorStartingModule: jsondata.questions.errorStartingModule,
                        oops: jsondata.questions.oops,
                        forbidden: jsondata.common.forbidden,
                        textDirection: jsondata.language.textDirection,
                        days: jsondata.questions.days,
                        next: jsondata.questions.next,
                        previous: jsondata.questions.previous
                    };
                    localStorage.setItem('question-language', JSON.stringify(this.questionLanguage));
                });
        }
    },
    getters: {
        getAppLanguages: (state) => {
            return state.appLanguages;
        },
        getAppLanguageById: (state) => (id: string | undefined):AppLanguageModel | undefined => {
            return state.appLanguages.find(p => p.id === id);
        }
    }
});

function getFile(languageId: string) {
    switch (languageId) {
        case LanguageIds.English:
            return LanguageFiles.English;
        case LanguageIds.Hebrew:
            return LanguageFiles.Hebrew;
        case LanguageIds.Arabic:
            return LanguageFiles.Arabic;
        case LanguageIds.Portuguese:
            return LanguageFiles.Portuguese;
        case LanguageIds.Spanish:
            return LanguageFiles.Spanish;
        case LanguageIds.French:
            return LanguageFiles.French;
        case LanguageIds.Russian:
            return LanguageFiles.Russian;
        default:
            return getLanguageForLocale();
    }
}

export default useAppLanguageStore;