import {useRouter} from "vue-router";
import useAppLanguageStore from "@/settings/languages/AppLanguageStore";
import useAuthStore from "@/framework/auth/AuthStore";
import {getAuthToken, getCurrentUser, logoutCommand} from "@/framework/auth/AuthService";
import {isPlatform} from "@ionic/vue";
import {enableInAppPurchasing} from "@/payment/MobilePaymentService";
import useParticipantCourseModuleStore from "@/courses/ParticipantCourseModuleStore";

export default function useAuthenticationHelper() {
    const languageStore= useAppLanguageStore();
    const participantCourseModuleStore = useParticipantCourseModuleStore();
    const authStore = useAuthStore();
    const router = useRouter();
    
    async function completeLoginSetup(token: string, expiresIn: number, accessToken: string) {
        const authToken = await getAuthToken(token);

        if (!accessToken) {
            throw new Error('Could not get token');
        }

        await authStore.finishLogin({token: authToken!.token, expiresIn, accessToken});
        const user = await getCurrentUser();
        if (!user) {
            await router.push({name: 'register'});
        }

        authStore.user = user;
        await languageStore.loadLanguage(authStore.user.defaultLanguageId);
        await authStore.loadPolicyQueryString();
        const timesCalled = 1;
        await loadAppData(timesCalled);
        if ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
            await enableInAppPurchasing();
        }
    }
    async function loadAppData(retryCount: number) {
        if (retryCount > 5) {
            alert(languageStore.language.loginPrompts.ErrorLoadingData);
            return;
        }
        try {
            await participantCourseModuleStore.reloadParticipantCourseModules();
        } catch(e) {
            await loadAppData(retryCount + 1);
        }
    }

    const CODE_VERIFIER_KEY = 'cognito-code-verifier';
    const STATE_KEY = 'cognito-state';
    const CLIENT_ID = '7oqpqksnif4tbbptf3u54cdsbe';
    const COGNITO_URL = 'https://aristotle.auth.us-east-2.amazoncognito.com';

    function getAppClientId(): string {
        return process.env.VUE_APP_CLIENT_ID;
    }

    function getRedirectHost(): string{
        // for local development on an emulator desktop returns true
        if((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
            return 'aristotle:/';
        }
        const port = window.location.port==='443'?':443':''
        return `${window.location.protocol}//${window.location.host}${port}`;
    }

    function getRedirectUrl(): string {
        return `${getRedirectHost()}/logged-in`;
    }

    function getBaseOauthUrl(): string {
        return getCognitoUrl() + '/oauth2';
    }

    function getCognitoUrl() {
        return process.env.VUE_APP_COGNITO_URL;
    }

    function getLoginUrl(state: string, codeChallenge: string) {
        const baseUrl = getBaseOauthUrl() + '/authorize';
        const clientId = getAppClientId();
        const redirectUrl = getRedirectUrl();

        let scopes = 'openid email';
        // admin scope is necessary to allow the user to change their Cognito password
        scopes += ' aws.cognito.signin.user.admin';

        return encodeURI(`${baseUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${state}&scope=${scopes}&code_challenge_method=S256&code_challenge=${codeChallenge}`);
    }

    function getForgotPasswordUrl(state: string, codeChallenge: string):string {
        const baseUrl = getCognitoUrl() + '/forgotPassword';
        const clientId = getAppClientId();
        const redirectUrl = getRedirectUrl();


        let scopes = 'openid email';
        // admin scope is necessary to allow the user to change their Cognito password
        scopes += ' aws.cognito.signin.user.admin';

        return encodeURI(`${baseUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${state}&scope=${scopes}&code_challenge_method=S256&code_challenge=${codeChallenge}`);
    }

    function gotoLogout() {
        window.location.href = getLogoutUrl();
    }

    function getLogoutUrl(): string {
        const loginUrl = getRedirectHost() + '/login';
        return `${getCognitoUrl()}/logout?client_id=${getAppClientId()}&logout_uri=${loginUrl}`;
    }


    /**
     * Logout the user, first by expiring the token in the API and then by redirecting the user to Cognito to log in again.
     * Ignore errors "logging out" the token from the API because that most likely means it was already expired.
     */
    async function logout() {
        try {
            await logoutCommand();
        } finally {
            gotoLogout();
        }
    }

    return {gotoLogout, getForgotPasswordUrl, getLoginUrl, getBaseOauthUrl,
        getRedirectUrl, getRedirectHost, getAppClientId, COGNITO_URL, CLIENT_ID,
        STATE_KEY, CODE_VERIFIER_KEY, completeLoginSetup
    }
}